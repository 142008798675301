<script setup lang="ts">
defineOptions({
  name: 'JobRedirect',
});

const { $mopI18n, $urls } = useNuxtApp();
const { fetchJob, jobItemRef, getJobIdFromPath } = useMopJob();
// @ts-ignore
const jobId = getJobIdFromPath(useRoute().params.jobId);
onMounted(async () => {
  let replacePath = $mopI18n.localePath($urls.CAREER_PAGE);
  if (jobId) {
    await Promise.all([fetchJob(jobId)]);
    if (jobItemRef.value.getId()) {
      replacePath += `/${jobItemRef.value.getUrl()}`;
    }
  }
  useRouter().replace(replacePath);
});
</script>

<template>
  <MopPageLoad class="loading" />
</template>
